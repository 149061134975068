<template>
  <div class="page more-depth">
    <div class="container">
      <img src="/img/icon/check-background-blue.svg" class="ui-check-button__image" />
      <div class="title">{{ $__t("회원가입이 완료되었습니다") }}</div>

      <div class="subtitle">{{ $__t("서비스 이용을 위해") }}</div>
      <div class="subtitle">{{ $__t("결제카드 등록이 필수입니다.") }}</div>

      <div class="page-footer">
        <button
          type="button"
          v-button
          class="payment-register-button"
          @click="onClickToAddPayMethod"
        >
          <span class="ui-submit-button__text">
            {{ $__t("결제수단 등록") }}
          </span>
        </button>

        <button
          type="button"
          v-button
          @click="onClickToHome"
          class="payment-noregister-button"
        >
          <span>
            {{ $__t("나중에 하기") }}
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formData: {
        user_name: {
          type: "text",
          validation: [{ type: "required" }],
          value: "",
          label: this.$__t("이름"),
          placeholder: this.$__t("이름을 입력해주세요"),
          error: "",
        },

        user_phone: {
          type: "text",
          label: this.$__t("휴대폰 번호"),
          placeholder: this.$__t("'-'제외하고 번호만 입력해주세요"),
          validation: [{ type: "required" }, { type: "number" }],
          input_mode: "number",
          error: "",
          value: "",
          mask: "###-####-####",
          cert_number: "",
          verifiing: false,
          need_to_retry: false,
          auth_key: null,
        },

        user_cert_num: {
          type: "text",
          label: this.$__t("인증번호"),
          placeholder: this.$__t("인증번호 6자리를 입력해주세요"),
          error: "",
          input_mode: "number",
          value: "",
        },
      },
    };
  },

  computed: {},

  methods: {
    async onClickToHome() {
      this.$store.commit("loading/SET_TRUE");

      // return this.$router.push({ name: 'Home' }); 그냥 Home으로 가면 로그인은 되어있는데 token이 없다.
      //this.$router.replace({ name: 'Home' });

      // @MEMO: 호스트인 경우는 무시
      location.replace("/");

      setTimeout(() => {
        this.$store.commit("loading/SET_FALSE");
      }, 500);
    },

    async onClickToAddPayMethod() {
      this.$store.commit("loading/SET_TRUE");
      this.$router.push({ name: "Home" });

      setTimeout(() => {
        // this.$router.push({ name: 'SelectPaymentForAdd', query: { redirect: 'BillingListAndChangePrimary' } });
        this.$router.push({ name: "SelectPaymentForAdd" });
      }, 0);

      setTimeout(() => {
        this.$store.commit("loading/SET_FALSE");
      }, 1000);
    },

    //here
    async onClickRequestCertNum(e) {
      let res;

      try {
        this.formData.user_phone.verifiing = true;

        res = await this.$store.dispatch("auth/authNumRequest", {
          user_name: this.formData.user_name.value,
          phone: this.formData.user_phone.value,
        });
        // console.log('res!!', res);
        // this.rerender = this.rerender + 1;
        this.formData.user_phone.auth_key = res.auth_key;
        this.formData.user_phone.need_to_retry = true;
      } catch (e) {
        console.error(e);
      } finally {
        this.loginFormData.user_phone.verifiing = false;
      }

      return;
    },
  },
};
</script>
<style lang="scss" scoped>
img {
  width: unit(72);
  height: unit(72);
}
button {
  min-height: unit(50);
  background-color: #a9afb3;
  border-radius: unit(6);
  &.payment-register-button {
    background-color: $color-primary-9;
  }
  &.payment-noregister-button {
    color: #a0a0a0;
    font-weight: 600;
    background-color: #ffffff;
  }
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .title {
    margin-top: unit(28);
    font-size: unit(24);
    font-weight: 600;
  }
  .subtitle {
    color: #787878;
    font-size: unit(16);
  }
}

.phoneNum-requestCert {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  .btn-certify {
    width: unit(50);
    color: #2c6790;
    font-size: unit(14);
  }
}
.certNum-timer-resend {
  display: flex;
  flex-direction: row;
  align-items: center;
  .timerandresend {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: unit(100);
    gap: unit(4);
    color: #2c6790;
    font-size: unit(14);
  }
}
</style>
